const Boxes = {
    box8: function () {
      var brd2 = JXG.JSXGraph.initBoard('jxgbox1a',{boundingbox: [-8, 10, 8, -6],keepaspectratio: true, axis:false, ticks:false, grid:true, pan:{enabled:false}, showCopyright:false,
      showNavigation:false});
      brd2.suspendUpdate();
      var resize = function () {
          brd2.resizeContainer(brd2.containerObj.clientWidth, brd2.containerObj.clientHeight, true);
          brd2.fullUpdate();
          };
      window.onresize = resize;
      brd2.create('text', [0,9, '<b> Principle of Continuity</b>'], {fixed:true, anchorX:'middle', fontSize:function(){return 32*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      brd2.create('text', [0,8, 'v_2 = v_1 A_1/A_2'], {fixed:true, anchorX:'middle', fontSize:function(){return 22*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      var line1=brd2.create('line', [[-4.0,-1],[-4.0,+1]],{visible:false});
      var line2=brd2.create('line', [[0,-1],[0,+1]],{visible:false});
      var line3=brd2.create('line', [[4.0,-1],[4.0,+1]],{visible:false});
      var p1 = brd2.create('glider', [-4.,1, line1], {name:'A', size:3, face: 'circle', fillColor:'yellow', strokeColor:'black', label:{offset:[0, 15], cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(12*brd2.canvasWidth/800.)}}});
      var p2 = brd2.create('glider', [0,2, line2], {name:'B', size:3, face: 'circle', fillColor:'yellow', strokeColor:'black',label:{offset:[0, 15],cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(12*brd2.canvasWidth/800.)}}});
      var p3 = brd2.create('glider', [4,1, line3], {name:'C', size:3, face: 'circle', fillColor:'yellow', strokeColor:'black', label:{offset:[0, 15],cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(12*brd2.canvasWidth/800.)}}});
    //  var c = brd2.create('spline', [p1, p2, p3], {strokeWidth:8, strokeColor:'black', visible:false});
    //  var refl = brd2.create('line', [[-1,0],[+1,0]],{visible:false});
    //  var d = brd2.create('reflection',[c, refl],{strokeWidth:8, strokeColor:'black', visible:false});
      var ft = function(x){return  p2.Y()+(p3.Y()-p1.Y())*x/8+(p1.Y()+p3.Y()-2*p2.Y())*x*x/32;};
      var fb = function(x){return -p2.Y()-(p3.Y()-p1.Y())*x/8-(p1.Y()+p3.Y()-2*p2.Y())*x*x/32;};
      brd2.create('functiongraph', [function(x){return ft(x)}, -4, 4],{strokeColor:'black', strokeWidth:2});
      brd2.create('functiongraph', [function(x){return fb(x)}, -4, 4],{strokeColor:'black', strokeWidth:2});
      var x0 = -4;
      var x1=-4;
      var sw=0;
    //  var tp = brd2.create('point', [x0, 0],{name:'2', size:0, fillColor:'red', strokeColor:'black', trace:false});
    //  var lin=brd2.create('line',[[function(){return x0}, -1], [function(){return x0}, 1]],{visible:false});
//Turtles
      var pt =brd2.create('point', [()=>x0, ()=>ft(x0)],{visible:false});
      var pb =brd2.create('point', [()=>x0, ()=>fb(x0)],{visible:false});
      var t0 = brd2.create('turtle', [-4, 0],{dash:1});
      var tt = brd2.create('turtle', [-4, 0.5*ft(-4)],{dash:1});
      var tb = brd2.create('turtle', [-4, 0.5*fb(-4)],{dash:1});
      tt.setPenSize(1);
      t0.setPenSize(1);
      tb.setPenSize(1);
      tt.setPenColor('blue');
      t0.setPenColor('red');
      tb.setPenColor('green');
      var run = function(){
        if (x1 <= 4.05) {  // stop after two rounds
          x1 += (p1.Y()*p1.Y()/pt.Y()/pt.Y())*0.05;
          x0 =Math.min(4, x1);
          t0.moveTo([x0, 0]);
          tt.moveTo([x0, 0.5*ft(x0)]);
          tb.moveTo([x0, 0.5*fb(x0)]);
          if(sw==1){
          setTimeout(run, 0);}
        else{
          return;
        }}};
      t0.hideTurtle();
      tt.hideTurtle();
      tb.hideTurtle();
      var chg = brd2.create('image', ['/assets/fan.svg', [-8,-1.5],[3,3]],{fixed:true});
      chg.on('down', function(){x1=-4; x0=-4; sw=1;tt.cs();tb.cs();t0.cs();t0.init(x0, 0);tt.init(x0, 0.5*ft(x0));tb.init(x0, 0.5*fb(x0));run()});
      p1.on('down', function(){x1=-4; x0=-4; sw=0; tt.cs(); tb.cs(); t0.cs();t0.cs();t0.init(x0, 0);tt.init(x0, 0.5*ft(x0));tb.init(x0, 0.5*fb(x0));});
      p2.on('down', function(){x1=-4; x0=-4; sw=0; tt.cs(); tb.cs(); t0.cs();t0.cs();t0.init(x0, 0);tt.init(x0, 0.5*ft(x0));tb.init(x0, 0.5*fb(x0));});
      p3.on('down', function(){x1=-4; x0=-4; sw=0; tt.cs(); tb.cs(); t0.cs();t0.cs();t0.init(x0, 0);tt.init(x0, 0.5*ft(x0));tb.init(x0, 0.5*fb(x0));});
      //var mdt =brd2.create('midpoint', [tp, pt],{name:'1',size:0,  fillColor:'blue', strokeColor:'black'});
      //var mdb =brd2.create('midpoint', [tp, pb],{name:'3',size:0, fillColor:'green', strokeColor:'black', trace:false});
      brd2.create('image', ['/assets/autumn-tree.svg', [-5.5, -1.5],[2.5,3]],{fixed:true});
      brd2.create('image', ['/assets/leaf.svg', [()=>x0-0.1, ()=>0.5*ft(x0)-0.25],[0.5,0.5]],{fixed:true, rotate:45});
      brd2.create('image', ['/assets/leaf.svg', [()=>x0+0.1, ()=>0.5*fb(x0)-0.4],[0.5,0.5]],{fixed:true, rotate:60});
      brd2.create('image', ['/assets/leaf.svg', [()=>x0, 0],[0.5,0.5]],{fixed:true, rotate:-15});
//
      brd2.create('image', ['/assets/arrowhead.svg', [-5., function(){return p1.Y()/2 +0.2}],[0.25,0.25]],{fixed:true,visible:function(){if(sw==0){return false} else{return true}}, rotate:-90});
      brd2.create('image', ['/assets/arrowhead.svg', [-5., 0.125],[0.25,0.25]],{fixed:true, visible:function(){if(sw==0){return false} else{return true}}, rotate:-90});
      brd2.create('image', ['/assets/arrowhead.svg', [-5., function(){return -p1.Y()/2+0.1}],[0.25,0.25]],{fixed:true,visible:function(){if(sw==0){return false} else{return true}}, rotate:-90});
      brd2.create('text', [-6, 1, 'v_1= 1.00 m/s'],
          {anchorX:'middle', cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(18*brd2.canvasWidth/800.)}, parse:true,fixed:true});
      brd2.create('text', [0,7, ()=> '<b>A_2 = </b>'+(pt.Y()*pt.Y()/p1.Y()/p1.Y()).toFixed(2)+' <b>A_1</b>'], {fixed:true, anchorX:'middle', fontSize:function(){return 22*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      brd2.create('text', [()=>x0+0.25, ()=>ft(x0)+2, function(){return 'v_2 =' + (p1.Y()*p1.Y()/pt.Y()/pt.Y()).toFixed(2)+' m/s'}],
          {anchorX:'middle',cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(18*brd2.canvasWidth/800.)}, parse:true,fixed:true});
      //brd2.create('text', [-3.75, ()=>ft(-4)+2, function(){return 'v(@x=-4) =' + (p1.Y()*p1.Y()/p1.Y()/p1.Y()).toFixed(2)+' m/s'}],
      //        {cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(12*brd2.canvasWidth/500.)}, parse:true,fixed:true});
      //brd2.create('text', [()=>x0, ()=>ft(x0)+1, 'x'], {strokeWidth:1, strokeColor:'grey'});
      brd2.create('segment', [[()=>x0, ()=>ft(x0)], [()=>x0, ()=>ft(x0)+2]], {strokeWidth:1, strokeColor:'grey'});
      brd2.unsuspendUpdate();
        }
      }
export default Boxes;
