<template>
  <div>
    <h3 ref="def">
      Principle of Continuity
    </h3>
    <p>
      Principle of continuity is a consequence of conservation of mass.
      Principle of continuity requires that during the flow of a fluid, the fluid must flow in such a way that its mass remains conserved.
      This imposes certain restrictions that the fluid velocity vector should obey when the fluid is flowing through a duct or a channel. Consider two points 'A' and 'B' along the flow of the fluid.
      The principle of continuity then implies:
      $$ \rho_A A_A v_A = \rho_B A_B v_B$$
      where \(\rho_A\) and \(\rho_B\) are the fluid densities, \(A_A\) and \(A_B\) are the cross-section areas and \(v_A\) and \(v_B\) are the fluid velocities at the two points, respectively.
    </p>
    <h3 ref="origin">
      Derivation of Continuity Equation
    </h3>
    <p>
      To understand how conservation of mass places restrictions on the velocity field, we consider the steady flow of a fluid through a duct. The cross-section of the duct varies along its length i.e. the cross-section at the inlet is different from the cross-section at the outlet.
      Further, since the flow of the fluid is one-dimensional, the velocity and the density are assumed not to change over a cross-section of the duct.
      <br>
      <v-layout justify-center>
        <v-img src="/assets/duct.png"
               max-height="350px"
               max-width="450px"
               width="400px"
               contain
        />
      </v-layout>
      <br>
      Let \(v_1\) denote the speed of the fluid at the inlet and \(v_2\) denote the speed of the fluid at the outlet.
      Let \(\rho_1\) denote the density of the fluid at the inlet and \(\rho_2\) denote the density of the fluid at the outlet.
      Let \(A_1\) denote the cross-section area of the fluid at the inlet and \(A_2\) denote the cross-section area of the fluid at the outlet.
      <br>
      The mass of the fluid entering the duct in a time interval \(\Delta t\) is given as:
      $$\Delta m_i = \rho_1 A_1 v_1 \Delta t$$
      The mass of the fluid leaving the duct in the same interval is given as:
      $$\Delta m_o = \rho_2 A_2 v_2 \Delta t$$
      The conservation of mass requires that mass entering the duct be same as the mass leaving the duct. Thus, we have:
      $$\Delta m_i = \Delta m_o$$
      which leads to
      $$ \rho_1 A_1 v_1 \Delta t =  \rho_2 A_2 v_2 \Delta t$$
      or
      $$ \rho_1 A_1 v_1 =  \rho_2 A_2 v_2$$
    </p>
    <h3 ref="incompressible">
      Incompressible Flows
    </h3>
    <p>
      For incompressible flows, the density of the fluid remains constant during the flow, i.e. \(\rho_1 = \rho_2\). Thus, the continuity equation for such flows can be written as:
      $$ v_1 A_1 = v_2 A_2$$
    </p>
    <h3 ref="playgraph">
      MagicGraph: Fluid Flow thorough a Duct
    </h3>
    <p>
      This MagicGraph lets you visualize how the velocity of a fluid element changes as it flows through a duct with variable cross-section.
      Drag points A, B, and C around to change the cross-section geometry of the duct. Then, tap on the blower to start the MagicGraph.
    </p>
    <v-responsive>
      <v-layout align-center justify-center>
        <div id="jxgbox1a" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Continuity',
  created: function () {
    const title = 'Principle of Continuity';
    // Store mutations
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    //
    let newTopics = [
      {title: 'Principle of Continuity',img:'/assets/number-1.svg', action: () => this.goto('def')},
      {title: 'Derivation of Continuity Equation', img:'/assets/number-2.svg', action: () => this.goto('origin')},
      {title: 'Incompressible Flows', img:'/assets/number-3.svg', action: () => this.goto('incompressible')},
      {title: 'PlayGraph: Fluid Flow Through a Duct', img:'/assets/touch.svg', action: () => this.goto('playgraph')},
    ];
    //
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box8();
  }
  }
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
